import React, {Fragment, useState} from "react";
import packsData from '../packsData';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import CardOffer from './cardOffer';
import Modal from './modal';
import '../styles/offers.scss';
import Contact from "./contact";
import optionsData from "../optionsData";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';

const Offers = () => {
    const breakpoints = useBreakpoint();
    const [show, setShow] = useState(false);
    const [offerChoice, setOfferChoice ] = useState('');
    const [offerName, setOfferName ] = useState('');

    const handleShow = () => setShow(false);
    const handleChoiceOffer = (event) => {
        const choice = event.target.getAttribute('data-offer');
        const name = event.target.getAttribute('data-name');
        setShow(true);
        setOfferChoice(choice);
        setOfferName(name);

    }

    return (
        <section className={'container-offers'} id={'prices'}>
           <div className="container">
               <h2>Une offre adaptée</h2>
               { breakpoints.md ?
                   <>
                       <table>
                           <thead>
                               <tr>
                                   <td>
                                   </td>
                                   { Object.values(packsData).map((offer, offerKey) => {
                                       return(
                                           <th key={offerKey} scope={'col'} className={offer.mostPopular ? 'popular': ''}>
                                               <div className={`container-title ${offer.mostPopular ? 'container-popular': ''}`}>
                                                   {offer.mostPopular && <span className={'popular-tag'}>Populaire</span>}
                                                   <p className={`title ${offer.mostPopular ? 'discount-text': ''}`}>{offer.title}</p>
                                                   {
                                                       offer.priceMonthly ?
                                                           <Fragment>
                                                               <p className={'price'}>{offer.priceMonthly} <span className={'currency'}>€{offer.priceMonthly !== '0' && '/mois'}</span></p>
                                                               <p className={`infos ${offer.infosUser}`}>{offer.infosUser}{offer.id === 3 && '*'}</p>
                                                           </Fragment>
                                                           : <p className={'price'}>Sur mesure</p>
                                                   }
                                               </div>
                                           </th>
                                       )
                                   })
                                   }
                               </tr>
                           </thead>
                           <tbody>
                               {
                                   Object.values(packsData)[0].offers.map((offerName, offerNameKey) => {
                                       return (
                                           <tr key={offerNameKey}>
                                               <th scope={'row'}>- {offerName.name} {offerName.info && (
                                                   <Tippy content={offerName.info} placement="right" hideOnClick>
                                                       <span>ⓘ</span>
                                                   </Tippy>
                                               )}</th>
                                               {
                                                   Object.values(packsData).map((offer, offerKey) => {
                                                       return(
                                                           <td key={offerKey} className={offer.mostPopular ? 'popular': ''}>
                                                               {offer.offers[offerNameKey].isIncluded && !offer.offers[offerNameKey].comingSoon && (
                                                                   <div className={`icon-check ${offer.mostPopular ? 'check-popular' : ''}`} />
                                                               )}
                                                               {offer.offers[offerNameKey].isIncluded && offer.offers[offerNameKey].comingSoon && (
                                                                   <div className={`${offer.mostPopular ? 'discount-text' : ''}`} >
                                                                       Arrivera bientôt
                                                                   </div>
                                                               )}
                                                           </td>
                                                       )
                                                   })
                                               }
                                           </tr>
                                       )
                                   })
                               }
                               <tr className="table-offer-button">
                                   <td />
                                   {
                                       Object.values(packsData).map((offer, offerKey) => {
                                           const price = `${offer.priceMonthly}€/mois`;
                                           return(
                                               <td className={`text-center ${offer.mentions ? 'container-mentions' : ''} ${offer.mostPopular ? 'popular': ''}`} key={offerKey}>
                                                   <button className={`btn ${offer.mostPopular ? 'btn-popular' : ''}`} data-name={offer.title} data-offer={`${offer.title} - ${offer.id === 4 ? 'Sur mesure' : `${price}- mensuel`}`} onClick={event => handleChoiceOffer(event)}>Démarrer</button>
                                                   {offer.mentions && <span className={'mentions'}>{offer.mentions}</span>}
                                               </td>
                                           )
                                       })
                                   }
                               </tr>
                               <tr className="subtitle">
                                   <td></td>
                                   <td></td>
                                   <td colSpan={Object.values(packsData).length - 1}>
                                       En option
                                   </td>
                               </tr>
                               {
                                   Object.values(optionsData.options).map(option => (
                                       <tr key={option.name}>
                                           <th scope={'row'}>- {option.name}  {option.info && (
                                               <Tippy content={option.info} placement="right" hideOnClick interactive appendTo={() => document.body}>
                                                   <span>ⓘ</span>
                                               </Tippy>
                                           )}</th>
                                           <td />
                                           <td className="border-primary" colSpan={Object.values(packsData).length - 1}>
                                               {option.price ? (
                                                   <>
                                                       {option.price}
                                                       <sup>€/mois{option.priceAsterisk}</sup>
                                                   </>
                                               ) : 'BIENTÔT'}
                                           </td>
                                       </tr>
                                   ))
                               }
                           </tbody>
                       </table>
                       <ul className="asterisk">
                           {optionsData.asterisk && optionsData.asterisk.map(a => <li key={a}>{a}</li>)}
                       </ul>
                   </>
                   :
                       <>
                           {Object.values(packsData).map((offer, offerKey) => {
                               const price = `${offer.priceMonthly}€/mois`;
                               return (
                                   <Fragment key={offerKey}>
                                       <CardOffer
                                           title={offer.title}
                                           price={offer.priceMonthly}
                                           info1={offer.infosUser}
                                           info2={offer.infosPayment}
                                           btnContent={'Démarrer'}
                                           detailsList={offer.offers}
                                           isPopular={offer.mostPopular}
                                           isCustomization={offer.id === 4}
                                           datasetOfferBtn={`${offer.title} - ${offer.id === 4 ? 'Sur mesure' : `${price}- mensuel`}`}
                                           datasetNameBtn={offer.title}
                                           handleClick={event => handleChoiceOffer(event)}
                                       />
                                   </Fragment>
                               )
                           })}
                           {Object.values(optionsData.options).length > 0 && (
                               <div className="card">
                                   <p className={`title`}>EN OPTIONS</p>
                                   <dl className="options">
                                       {Object.values(optionsData.options).map(option => (
                                            <>
                                                <dt>
                                                    {option.name}  {option.info && (
                                                        <Tippy content={option.info} hideOnClick interactive appendTo={() => document.body}>
                                                            <span>ⓘ</span>
                                                        </Tippy>
                                                    )}
                                                </dt>
                                                <dd>
                                                    {option.price ? (
                                                        <>
                                                            {option.price}
                                                            <sup>€/mois{option.priceAsterisk}</sup>
                                                        </>
                                                    ) : 'BIENTÔT'}
                                                </dd>
                                            </>
                                       ))}
                                   </dl>
                               </div>
                           )}
                           <ul className="asterisk">
                               {optionsData.asterisk && optionsData.asterisk.map(a => <li key={a}>{a}</li>)}
                           </ul>
                       </>
               }
               {show &&
                   <Modal show={show} close={handleShow}>
                       <Contact formName={'pricing'} offerChoice={offerChoice} offerName={offerName}/>
                   </Modal>
               }
           </div>
        </section>
    )
}


export default Offers;
