import React, {Fragment, useState} from "react";
import '../styles/offers.scss';

const CardOffer = (props) => {

    const [isDetails, setIsDetails] = useState(false);
    return (
            <div className={`card ${props.isPopular ? 'container-popular' : ''}`}>
                {props.isPopular &&
                    <span className="popular-tag">Populaire</span>
                }
                <p className={`title ${props.isPopular ? 'discount-text' : ''}`}>{props.title}</p>
                {props.isCustomization ?
                    <p className={'customization'}>Sur mesure</p>
                    : <Fragment>
                        <p className="price">{props.price}<span className={'currency'}>€{props.price !== '0' && '/mois'}</span></p>
                        <p className="infos">{props.info1}</p>
                        <p className={`infos ${props.isPopular ? 'discount-text' : ''}`}>{props.info2}</p>
                    </Fragment>
                }
                <button className={`btn ${props.isPopular ? 'btn-popular' : ''}`} data-name={props.datasetNameBtn} data-offer={props.datasetOfferBtn} onClick={(event) => props.handleClick(event) }>{props.btnContent}</button>
                <button className="btn-detail" aria-pressed={isDetails} onClick={() => setIsDetails(!isDetails)}>Voir le détail de l'offre</button>
                {isDetails &&
                <ul>
                    {
                        props.detailsList.filter(offer => offer.isIncluded).map((included, key) => <li key={key}>- {included.name}</li>)
                    }
                </ul>
                }
            </div>
    )
}


export default CardOffer;
