import React, {useState} from "react";
import Modal from './modal';
import '../styles/prefooter.scss';
import Contact from "./contact";

const PreFooter = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(false);
    return (
        <section className={'pre-footer'}>
            <div className="container">
                <h2>Commencez à vendre avec TetraWeb dès aujourd’hui</h2>
                <p>Essayez Tetraweb gratuitement pour découvrir tous les outils et
                    services dont vous avez besoin pour lancer, gérer et développer votre
                    entreprise.</p>
                <button className={'btn'} onClick={() => setShow(true)}>Démarrer mon site</button>
                {show &&
                    <Modal show={show} close={handleShow}>
                        <Contact formName={'signin'}/>
                    </Modal>
                }
            </div>
        </section>
    )
}


export default PreFooter;
