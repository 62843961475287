import React, {useState} from "react";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import '../styles/banner.scss';
import Card1 from '../images/examplesite1.png';
import Card2 from '../images/examplesite2.png';
import Card3 from '../images/examplesite3.png';
import Card4 from '../images/examplesite4.png';
import Card5 from '../images/examplesite5.png';
import Input from './input';
import Modal from './modal';
import Contact from "./contact";


const Banner = () => {
    const breakpoints = useBreakpoint();
    const [userMail, setUserMail] = useState('');
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(false);

    const handleChangeMail = (value) => {
        setUserMail(value);
    }
    const onSubmitMail = (e) => {
        e.preventDefault();
        setShow(true);
    }
    return (
        <section className={'container-banner'} id={'banner'}>
            <div className="container">
                <div className="wrapper">
                    <div className="title">
                        <h1>Boostez votre activité avec des offres complémentaires !</h1>
                        <p>Développons votre activité ensemble !</p>
                        <form className={'input-group-mail'} onSubmit={onSubmitMail}>
                            <Input
                                type={"email"}
                                name={"userMail"}
                                id={"userMail"}
                                placeholder={'Adresse mail'}
                                pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                                required={true}
                                onchange={handleChangeMail}
                                aria-label={"susbscribe"}
                            />
                            <button className={'btn-modal btn-start'} type={'submit'}>Démarer</button>
                        </form>
                        {show &&
                            <Modal show={show} close={handleShow}>
                                <Contact emailValue={userMail} formName={'signin'}/>
                            </Modal>
                        }
                    </div>
                    {breakpoints.md &&
                    <div className="container-imgs-lg">
                        <div className="container-img">
                            <img src={Card4} alt="illustration du site d'e-motors, importateur automobile"/>
                        </div>
                        <div className="container-img">
                            <img src={Card5} alt="illustration d'un site vitrine automobile"/>
                        </div>
                    </div>
                    }

                </div>
                <div className="container-imgs">
                    <div className="container-img">
                        <img src={Card1} alt=""/>
                    </div>
                    <div className="container-img">
                        <img src={Card2} alt=""/>
                    </div>
                    <div className="container-img">
                        <img src={Card3} alt=""/>
                    </div>
                </div>
            </div>
        </section>
    )
}





export default Banner;
