import React from "react";
import picture1 from '../images/img-desktop-mobile.png';
import picture2 from '../images/img-multi-device.png';
import picture3 from '../images/img-interface.png';
import '../styles/overview.scss';

const Overview = () => {
    return (
        <section className={'container-overview'}>
            <div className="container">
                <h2>Toujours à vos côtés, <span>dès les premières ventes</span></h2>
                <p className={'intro'}>Une plateforme unique avec toutes les fonctions de e-commerce et de point de vente nécessaires pour créer, gérer et augmenter
                    votre chiffre d’affaires.</p>
                <div className={'col-2'}>
                    <div className="container-img">
                        <img src={picture1} alt="illustration d'un écran d'ordinateur et d'un smartphone"/>
                    </div>
                    <div className="text">
                        <p className="">Gérez tout votre stock et le parcours client au même endroit !<br />
                            Bénéficiez du stock emotors comme si c’était le votre !</p>
                        <a href="#prices" className={'btn btn-discover'}>Découvrez nos outils pour <span>démarrer votre site</span></a>
                    </div>
                </div>
                <div className="col-2 col-reverse">
                    <div className="text">
                        <p>Création de mini site dédié sur la base du CRM. Prenez le contrôle et augmenter votre visibilité !</p>
                        <a href="#prices" className={'btn btn-discover'}>Découvrez nos outils pour <span>démarrer votre site</span></a>
                    </div>
                    <div className="container-img">
                        <img src={picture2} alt="illustration de différents supports tels que ordinateur, smartphone, tablette et ordinateur portable"/>
                    </div>
                </div>
            </div>
            <div className="interface">
                <div className="col-2 container">
                    <div className="container-img">
                        <img src={picture3} alt="icône de personnalisation"/>
                    </div>
                    <div className="text">
                        <h2>Une interface <span>à votre image</span></h2>
                        <p>Utilisez des outils intégrés à votre back office pour créer, exécuter et analyser vos ventes.</p>
                        <a href="#prices" className={'btn btn-discover'}>Découvrez nos outils pour <span>démarrer votre site</span></a>
                    </div>
                </div>

            </div>
        </section>
    )
}


export default Overview;
