import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner";
import Overview from "../components/overview";
import Offers from "../components/offers";
import References from "../components/references";
import PreFooter from "../components/preFooter";

const IndexPage = () => (
  <Layout>
    <SEO title="Mini site" description={'Personnaliser votre site en ligne'} lang={'fr'}/>
    <main id={'content-page'}>
        <Banner />
        <Overview />
        <Offers />
        <References />
        <PreFooter />
    </main>
  </Layout>
)

export default IndexPage
