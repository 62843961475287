import React from "react";
import imgQuote from '../images/img_emotors.jpg';
import logoAutoFactoria from '../images/logo-autofactoria.png';
import logoAutoGarage from '../images/logo-autogarage.png';
import logoGarageAuto from '../images/logo-garage-auto.png';
import logoGarageAutoDesign from '../images/logo-garage-autodesign.png';
import logoIndy from '../images/logo-indy.png';
import logoEmotors from '../images/logo-emotors.png';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import '../styles/references.scss';

const References = () => {
    const breakpoints = useBreakpoint();
    return (
        <section className={'container-references'}>
            <h2>Ils nous font confiance</h2>
            <div className="container-quotation">
                <div className="container-img profile">
                    <img src={imgQuote} alt="portrait de louis dupont, ceo indy storage"/>
                </div>
                <blockquote>
                    <p>« En seulement 2 ans, nous
                        avons réussi à créer quelque
                        chose que de nombreuses
                        marques n’ont toujours pas
                        réalisé au bout de 5 ans. »</p>
                    <div className="container-img">
                        <img src={logoEmotors} alt="logo indy"/>
                    </div>
                </blockquote>
            </div>
            <div className="references">
                <div className="container-logo">
                    <img src={logoGarageAuto} alt="logo garage auto" className={'logo-l'}/>
                </div>
                <div className="container-logo">
                    <img src={logoAutoFactoria} alt="logo auto factoria" className={'logo-l'}/>
                </div>
                <div className="container-logo">
                    <img src={logoAutoGarage} alt="logo auto garage"/>
                </div>
                { breakpoints.sm &&
                    <div className="container-logo">
                        <img src={logoIndy} alt="logo indy" className={'logo-l'}/>
                    </div>
                }
                { breakpoints.md &&
                   <div className="container-logo">
                       <img src={logoGarageAutoDesign} alt="logo garage auto design" className={'logo-xl'}/>
                   </div>
                }
            </div>
        </section>
    )
}


export default References;
